import Theme from "@/theme/types/theme";
import { TypographyProps } from "@mui/material";
import typography from "@/theme/typography";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h1: true;
    h2: true;
    h3: true;
    subtitle0: true;
    subtitle1: true;
    subtitle2: true;
    subtitle3: true;
    body1: true;
    body2: true;
    body3: true;
    body4: true;
    body5: true;
    body6: true;
    body7: true;
    button1: true;
    button2: true;
    button3: true;
    button4: true;
    button5: true;
    button6: true;
    button7: true;
    button8: true;
    caption1: true;
    caption2: true;
    caption3: true;
    caption4: true;
    caption5: true;
    caption6: true;
    value1: true;
    value2: true;
    value3: true;
    value4: true;
    value5: true;
    value6: true;
    value7: true;
  }
}

const handleVariant = (data: TypographyProps, style: any = {}) => {
  const typographyObj: any = typography;
  const variantAttr: string = data.variant || "";
  if (variantAttr && typographyObj[variantAttr])
    style = typographyObj[variantAttr];
  return style;
};

export default function Typography(theme: Theme) {
  const rootStyle = (ownerState: TypographyProps) => {
    const style: any = {};
    handleVariant(ownerState, style);
    return style;
  };
  return {
    MuiTypography: {
      styleOverrides: {
        // root: ({ ownerState }: { ownerState: TypographyProps }) =>
        //   rootStyle(ownerState),
      },
    },
  };
}
