/**
 * @file tools
 * @description 项目工具方法文件
 */
import { createRoot } from "react-dom/client";

/**
 * @func pxToVw
 * @description 将px转成vw
 */
export const pxToVw = (number = 0, totalWidth = 375, decimals = 5) => {
  let compWidth = totalWidth;
  const baseNumber = Math.pow(10, decimals);
  const numberVw =
    Math.floor((100 / compWidth) * number * baseNumber) / baseNumber;
  return numberVw + "vw";
};

/**
 * @func contentPxToVw
 * @description 转换内容
 */
export const contentPxToVw = (size: string | number, isChange: boolean) => {
  const reg = /([0-9]*)px/;
  const isTransform = typeof size === "string" && reg.test(size) && isChange;
  const isNumber = typeof size === "number" && isChange;
  const number: any = isTransform ? reg.exec(size) : 0;
  let handleSize = isTransform ? pxToVw(Number(number[1])) : size;
  handleSize = isNumber ? pxToVw(size) : handleSize;
  // console.log("contentPxToVw", size, isChange, handleSize);
  return handleSize;
};

/**
 * @func createUuid
 * @description 生成唯一的id
 */
export const createUuid = () =>
  Math.random().toString(36).substring(2) + Date.now().toString(36);

/**
 * @func mountComponentToBody
 * @description 挂在组件到dom树上
 * @param Component 组件内容
 * @param containerId 容器的id 用于获取dom 基本用不到
 * @returns 输出close方法
 * @example
 * const close = mountComponentToBody(<>内容</>)
 * close() // 关闭
 */
export function mountComponentToBody(
  Component: React.ReactNode,
  options?: { containerId?: string },
): () => void {
  const containerId = options?.containerId || createUuid();

  const rootContainer = document.createElement("div");
  rootContainer.id = containerId;
  document.body.appendChild(rootContainer);

  const root = createRoot(rootContainer);

  root.render(Component);

  return () => {
    root.unmount();
    document.body.removeChild(rootContainer);
  };
}

/**
 * 字符串判断,都转为大写去判断，以防大小写问题判断不准确
 * @param isStrValid(item, 'ARC20')
 * @return
 */
export const isStrValid = (str: string = "", includesStr: string = "") => {
  str = str.toUpperCase();
  includesStr = includesStr.toUpperCase();
  if (str.includes(includesStr)) {
    return true;
  }
  return false;
};
