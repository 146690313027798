import { TypographyVariant, Typography } from "./types/typography";
import { pxToVw } from "@/utils/tools";

const createVariant = (
  fontSize = 64,
  fontWeight = 600,
  lineHeight = 90,
  isChange = false,
  fontFamily = "sans-serif",
): TypographyVariant => {
  const variant: any = {
    fontSize: isChange ? pxToVw(fontSize) : `${fontSize}px`,
    fontWeight,
    lineHeight: isChange ? pxToVw(lineHeight) : `${lineHeight}px`,
    letterSpacing: "none",
    textTransform: "none",
  };
  if (fontFamily) variant.fontFamily = fontFamily;
  return variant;
};

const createTypography = (isToVw: boolean = false) => ({
  h1: createVariant(60, 900, 73, isToVw, "montserrat"),
  h2: createVariant(56, 900, 68, isToVw, "montserrat"),
  h3: createVariant(50, 900, 61, isToVw, "montserrat"),
  subtitle0: createVariant(38, 900, 47, isToVw, "montserrat"),
  subtitle1: createVariant(36, 900, 44, isToVw, "montserrat"),
  subtitle2: createVariant(28, 900, 34, isToVw, "montserrat"),
  subtitle3: createVariant(24, 900, 29, isToVw, "montserrat"),
  body1: createVariant(24, 600, 33, isToVw),
  body2: createVariant(20, 600, 28, isToVw),
  body3: createVariant(18, 600, 36, isToVw),
  body4: createVariant(16, 600, 22, isToVw),
  body5: createVariant(14, 600, 20, isToVw),
  body6: createVariant(14, 900, 18, isToVw, "montserrat"),
  body7: createVariant(18, 600, 24, isToVw),
  button1: createVariant(24, 900, 29, isToVw, "montserrat"),
  button2: createVariant(20, 900, 24, isToVw, "montserrat"),
  button3: createVariant(20, 600, 28, isToVw),
  button4: createVariant(16, 600, 22, isToVw),
  button5: createVariant(16, 600, 22, isToVw),
  button6: createVariant(16, 600, 22, isToVw),
  button7: createVariant(18, 600, 25, isToVw),
  button8: createVariant(24, 600, 33, isToVw),
  caption1: createVariant(24, 400, 33, isToVw),
  caption2: createVariant(16, 400, 22, isToVw),
  caption3: createVariant(14, 400, 20, isToVw),
  caption4: createVariant(14, 400, 20, isToVw),
  caption5: createVariant(14, 400, 21, isToVw),
  caption6: createVariant(12, 400, 17, isToVw),
  value1: createVariant(36, 600, 50, isToVw),
  value2: createVariant(32, 600, 45, isToVw),
  value3: createVariant(24, 600, 36, isToVw),
  value4: createVariant(20, 600, 28, isToVw),
  value5: createVariant(16, 600, 22, isToVw),
  value6: createVariant(16, 600, 22, isToVw),
  value7: createVariant(12, 600, 17, isToVw),
});

export default createTypography;
