import { ButtonProps } from "@mui/material";
import { alpha } from "@mui/material/styles";
import Theme from "@/theme/types/theme";

// NEW VARIANT
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    gradation: true;
    confirm: true;
    outlined: true;
    outConfirm: true;
  }
}
const globeCommonHandle = (styleObj: any, style: any = {}) => {
  styleObj.letterSpacing = "none";
  styleObj.textTransform = "none";
  style.display = "inline-flex";
  style.justifyContent = "center";
  style.textAlign = "center";
  style.alignItems = "center";
};
const normalSizeHandle = (style: any = {}) => {
  style.fontSize = "16px";
  style.lineHeight = "16px";
};

const buttonThemeHandle = (style: any = {}) => {
  const commonHandle = (styleObj: any) => {
    globeCommonHandle(styleObj, style);
    normalSizeHandle(style);
    style.padding = "11px 21px";
    style.borderRadius = "22px";
  };
  const emptyHandle = (styleObj: any) => {
    globeCommonHandle(styleObj, style);
    normalSizeHandle(style);
    style.padding = "0";
    style.border = "none";
    style.minWidth = "0";
  };
  const outlineCommonHandle = (styleObj: any, options: any) => {
    globeCommonHandle(styleObj, style);
    normalSizeHandle(style);
    style.borderRadius = "22px";
    const hoverObj: any = {};
    ["color", "background", "borderColor"].forEach((key) => {
      if (options[key] === "none") {
        style[key] = options[key];
      } else if (options[key]) {
        style[key] = options[key];
        hoverObj[key] = alpha(options[key], 0.6);
      }
    });
    style["&:hover"] = hoverObj;
  };
  return { commonHandle, emptyHandle, outlineCommonHandle };
};

const handleVariant = (data: ButtonProps, theme: Theme, style: any = {}) => {
  const { commonHandle, emptyHandle, outlineCommonHandle } =
    buttonThemeHandle(style);
  if (data.variant === "gradation") {
    console.log(style, data);
    commonHandle(style);
    style.background = "linear-gradient( 90deg, #FFED7C 0%, #70F0D6 100%)";
    // style.color = "#00655B";
  }
  if (data.variant === "confirm") {
    commonHandle(style);
    style.transition = "all .3s";
    style.color = "#fff";
    style.background = "#73C3B3";
    style.fontFamily = "montserrat";
    style.fontWeight = "900";
    style.fontSize = "16px";
    style["&:hover"] = {
      background: "#70F0D6",
      color: "#387B6D",
    };
    style["&.Mui-disabled"] = {
      background: "rgb(94, 95, 98)",
      color: "rgba(255, 255, 255, 0.3)",
      cursor: "not-allowed",
    };
  }
  if (data.variant === "outlined") {
    commonHandle(style);
    style.transition = "all .3s";
    style.color = "#fff";
    style.background = "transparent";
    style.fontFamily = "montserrat";
    style.fontWeight = "900";
    style.fontSize = "20px";
    style.border = "1px solid #505E81";
    style["&:hover"] = {
      background: "#C0C5E2",
      border: "none",
      color: "#515677",
    };
  }
  if (data.variant === "outConfirm") {
    commonHandle(style);
    style.transition = "all .3s";
    style.borderRadius = "10px";
    style.color = "#61DBC2";
    style.border = `1px solid #61DBC2`;
    style.fontWeight = "900";
    style.fontSize = "16px";
    style["&:hover"] = {
      background: "rgba(97, 219, 194, 0.30)",
      color: "#61DBC2",
    };
    style["&.Mui-disabled"] = {
      background: "rgb(94, 95, 98)",
      color: "rgba(255, 255, 255, 0.3)",
      cursor: "not-allowed",
      border: `none`,
    };
  }
  return style;
};

export default function Button(theme: Theme) {
  const rootStyle = (ownerState: ButtonProps) => {
    const style: any = {};
    handleVariant(ownerState, theme, style);
    return style;
  };

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState }: { ownerState: ButtonProps }) =>
          rootStyle(ownerState),
      },
    },
  };
}
