import React from "react";
import { useTerminal } from "@/hooks/env";

interface Props {
  pc: React.ReactNode;
  h5: React.ReactNode;
  main?: "pc" | "h5";
  hasMultipleTerminal?: boolean;
}

export default function SwitchTerminal(props: Props) {
  const { isMobile, initLoad } = useTerminal();
  const { pc, h5, main = "pc", hasMultipleTerminal = true } = props;
  const mainContent = main === "pc" ? pc : h5;
  const multipleContent = isMobile ? h5 || mainContent : pc;
  const content = hasMultipleTerminal ? multipleContent : mainContent;
  return <>{initLoad ? content : null}</>;
}
