import { Box, Stack } from "@mui/material";
import Header, { headerHeight } from "@/layouts/pc/header";
import React from "react";
import { footerHeight } from "@/layouts/pc/footer";
import { headerZIndex } from "@/config/config";
import dynamic from "next/dynamic";
const PcFooter = dynamic(() => import("@/layouts/pc/footer"), {
  ssr: false,
});

interface Props {
  children: React.ReactNode;
  footer?: React.ReactNode;
  contentHeight?: string | number;
  isFooter?: boolean;
}

export default function PcMain(props: Props) {
  const { children, contentHeight = "", isFooter = false } = props;
  return (
    <Box>
      {/* <Stack
        sx={{
          position: "sticky",
          top: 0,
          height: headerHeight,
          zIndex: headerZIndex,
        }}
      >
        <Header />
      </Stack> */}
      {/* <Stack
        minHeight={
          contentHeight
            ? contentHeight
            : `calc(100vh - ${headerHeight}px - ${footerHeight}px)`
        }
      > */}
      {children}
      {/* </Stack> */}
      {/* <Stack>{isFooter ? <PcFooter /> : null}</Stack> */}
    </Box>
  );
}
