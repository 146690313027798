import { ThemeProvider } from "@mui/material/styles";
// import theme from "@/theme/index";
import CssBaseline from "@mui/material/CssBaseline";
import { useTerminal } from "@/hooks/env";
import { pxToVw, contentPxToVw } from "@/utils/tools";
import { useCallback, useMemo } from "react";
import Theme from "./types/theme";
import GlobalStyles from "./GlobalStyles";
import { handleBreakpoints } from "@mui/system";
import { createTheme, ThemeOptions } from "@mui/material/styles";
import palette from "./palette";
import createTypography from "./typography";
import ThemeComponents from "./components/ThemeComponents";

interface Props {
  children: React.ReactNode;
  hasH5?: boolean;
}
export default function ThemeProviderComp(props: Props) {
  const { children, hasH5 = false } = props;
  const { isMobile } = useTerminal();
  const isChange = isMobile && hasH5;

  const baseOptions: Theme = useMemo(
    () => ({
      palette,
      typography: createTypography(isChange),
    }),
    [isChange],
  );
  const getPxToVw = useCallback(
    (size: string | number) => {
      return contentPxToVw(size, isChange);
    },
    [isChange],
  );

  // 间距h5处理
  const spacingChange = useCallback(
    (value: number | string) => {
      if (typeof value === "number") {
        const number = value * 8;
        return isChange ? pxToVw(number) : number;
      }
      return value;
    },
    [isChange],
  );
  // sx h5处理
  const changeKeys = [
    "width",
    "height",
    "fontSize",
    "minWidth",
    "minHeight",
    "min-width",
    "min-height",
  ]; // 获取如需要转换属性请增加
  const createSxConfig = (list: string[]) => {
    const obj: any = {};
    list.forEach((item) => {
      obj[item] = {
        style: (props: any) => {
          const change = (size: string | number) => {
            const handleSize = getPxToVw(size);
            return { [item]: handleSize };
          };
          return handleBreakpoints(props, props[item], change);
        },
      };
    });
    return obj;
  };

  if (isChange) {
    baseOptions["spacing"] = spacingChange;
    baseOptions["unstable_sxConfig"] = {
      ...createSxConfig(isChange ? changeKeys : []),
    };
  }

  const theme: any = createTheme(baseOptions);
  theme.components = ThemeComponents(theme);
  return (
    <ThemeProvider<Theme> theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
}
