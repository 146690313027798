/**
 * @file env
 * @description 项目环境判断文件
 */
/**
 * @func isMobileDevice
 * @description 是否为移动端
 */
export const isMobileDevice = (userAgent = "") => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent,
  );
};
/**
 * @func isIOS
 * @description 是否为ios手机端
 */
export const isIOS = (userAgent = "") => {
  return /iphone|ipad|ipod|ios/i.test(userAgent);
};
/**
 * @func isAndroid
 * @description 是否为Android端
 */
export const isAndroid = (userAgent = "") => {
  return /android|XiaoMi|MiuiBrowser/i.test(userAgent);
};

/**
 * @func isOKApp
 * @description 是否为OKApp
 */
export const isOKApp = (userAgent = navigator.userAgent) =>
  /OKApp/i.test(userAgent);

/**
 * @func isBitKeep
 * @description 是否为bitget App
 */
export const isBitKeep = (userAgent = navigator.userAgent) =>
  /BitKeep/i.test(userAgent);

/**
 * @func isWizzApp
 * @description 是否为wizz钱包 App
 */
export const isWizzApp = (userAgent = navigator.userAgent) =>
  /(WizzWallet)\/([\d\.]*)/.test(userAgent);

/**
 * @func isTokenPocket
 * @description 是否为TokenPocket钱包 App
 */
export const isTokenPocketApp = (userAgent = navigator.userAgent) =>
  userAgent.indexOf("TokenPocket") > -1;
