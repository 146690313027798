/**
 * @file page-architecture
 * @description 项目架构文件，尽量不涉及修改，为分离main脚本逻辑使用
 */

import ThemeProvider from "@/theme/ThemeProvider";
import SwitchTerminal from "@/components/public/switch-terminal";
import PcMain from "@/layouts/pc/main";
// import H5Main from "@/layouts/h5/main";
import { SnackbarProvider } from "notistack";
// import dynamic from "next/dynamic";
// const InitApp = dynamic(() => import("@/components/pc/init/app-init"), {
//   ssr: false,
// });

interface Props {
  children?: React.ReactNode;
  versions?: "v1" | "v2";
  isHeader?: boolean;
  isFooter?: boolean;
  h5IsHeader?: boolean;
  h5IsFooter?: boolean;
  activity?: React.ReactNode;
  hasPlaceholder?: boolean;
  hasH5?: boolean;
  h5IsOpenService?: boolean;
  h5Activity?: React.ReactNode;
}
interface Options {
  baseUrl: string;
  link: string;
  linkWallet?: React.ReactNode;
}
export default function PageArchitecture(props: Props) {
  const {
    hasH5 = false,
    isFooter = false,
    children = null,
    h5IsHeader = true,
    h5IsFooter = true,
  } = props;
  return (
    <ThemeProvider hasH5={hasH5}>
      <SnackbarProvider />
      {/* <InitApp /> */}
      <SwitchTerminal
        pc={<PcMain isFooter={isFooter}>{children}</PcMain>}
        h5={
          // <H5Main isHeader={h5IsHeader} isFooter={h5IsFooter}>
          //   {children}
          // </H5Main>
          null
        }
        hasMultipleTerminal={false}
      />
    </ThemeProvider>
  );
}
