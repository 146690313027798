import { alpha } from "@mui/material/styles";
import { Palette } from "./types/palette";
import { PaletteColor } from "@mui/material/styles";

declare module "@mui/material/styles/createPalette" {
  interface PaletteOptions extends Palette {}
}

const createPaletteColor = (color: string): PaletteColor => ({
  light: color,
  main: color,
  dark: color,
  contrastText: color,
});

const palette: any = {
  common: {
    black: "#0A0710",
    white: "#FFFFFF",
  },
  mode: "dark",
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: "#FFFFFF",
    secondary: "#B4B4B6",
    disabled: "#5E5F62",
  },
  active: {
    active: "#0083E6",
    hover: "#198FE8",
    disabled: "#5E5F62",
    disabledBackground: "#4A4E5B",
    default: "#61DBC2",
  },
  btn2Active: {
    active: "#C0C5E2",
    hover: "#C0C5E2",
    disabled: "#5E5F62",
    disabledBackground: "#4A4E5B",
    default: "#13161B",
  },
  btn3Active: {
    active: alpha("#505E81", 0.3),
    hover: alpha("#6D81B0", 0.3),
    disabled: "#C0C5E2",
    disabledBackground: alpha("#4A4E5B", 0.15),
    default: "#13161B",
  },
  background: {
    default: "#0A0710",
    paper: "#131117",
    textPaper: "#191B1F",
  },
  divider: "#343434",
  primary: createPaletteColor("#fff"),
  secondary: createPaletteColor("#61DBC2"),
  error: createPaletteColor("#F33838"),
  warning: createPaletteColor("#DD9141"),
  info: createPaletteColor("#B4B4B6"),
  success: createPaletteColor("#9DC854"),
  //   newProperty: "111",
};
export default palette as Palette;
