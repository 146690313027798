import { Stack } from "@mui/material";
import Image from "@/components/pc/Image";
import { Fragment } from "react";

export default function Home() {
  const list = [
    { img: "/assets/home_row_icon_1.png", width: "55px", height: "64px" },
    { img: "/assets/home_row_icon_2.png", width: "55px", height: "64px" },
    { img: "/assets/home_row_icon_3.png", width: "55px", height: "64px" },
    { img: "/assets/home_row_icon_4.png", width: "55px", height: "64px" },
    { img: "/assets/home_row_icon_5.png", width: "95px", height: "64px" },
  ];
  const baseLen = 8;
  return (
    <Stack
      width={"100vw"}
      height={"100vh"}
      overflow={"hidden"}
      sx={{
        backgroundImage: "url(/assets/home_bg.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Stack
        height={70}
        alignItems={"center"}
        direction={"row"}
        mb={"23px"}
        pt={"20px"}
        px={5}
      >
        <Image
          size={50}
          boxSx={{
            mr: "14px",
          }}
          src="/assets/logo_icon.png"
        />
        <Image
          width={144}
          height={29}
          boxSx={{
            mr: "18px",
          }}
          src="/assets/title.png"
        />
        <Image
          size={50}
          href={"https://x.com/hotdotmeme"}
          boxSx={{
            mr: "38px",
          }}
          src="/assets/x_icon.png"
        />
      </Stack>

      <Stack
        direction={"row"}
        flexWrap={"nowrap"}
        spacing={"39px"}
        width={"100vw"}
        overflow={"hidden"}
      >
        {new Array(baseLen).fill("").map((item, index) => (
          <Fragment key={index}>
            {list.map((listItem, key) => (
              <Image
                key={key}
                height={listItem.height}
                type="bg"
                width={listItem.width}
                src={listItem.img}
                boxSx={{
                  flex: "none",
                }}
              />
            ))}
          </Fragment>
        ))}
      </Stack>
      {/* <Image
        height={100}
        width={"100%"}
        // bgSize={"cover"}
        // type="bg"
        src="/assets/home_bg_2.png"
        boxSx={{
          mb: "152px",
        }}
      /> */}
      <Stack
        alignItems={"center"}
        justifyItems={"center"}
        width={"100%"}
        position={"absolute"}
        top={"calc(43vh - 157px)"}
      >
        <Image
          height={314}
          width={1265}
          type="bg"
          src="/assets/home_content.png"
        />
      </Stack>
    </Stack>
  );
}
