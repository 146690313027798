import { useState, useEffect } from "react";
import { isMobileDevice } from "@/utils/env";

/**
 * @func useTerminal
 * @description 获取终端信息
 */
export const useTerminal = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [initLoad, setInitLoad] = useState(false);

  const change = () => {
    const ua = navigator.userAgent;
    const mobile = isMobileDevice(ua);
    setIsMobile(mobile);
    setInitLoad(true);
  };

  useEffect(() => {
    change();
    window.addEventListener("resize", change);
    return () => {
      window.removeEventListener("resize", change);
    };
  }, []);
  return {
    isMobile,
    initLoad,
  };
};
