import { Stack, SxProps } from "@mui/material";
import NextLink from "next/link";

interface LinkProps {
  href?: string;
  children?: React.ReactNode;
  width?: number | string;
  height?: number | string;
}

function Link(props: LinkProps) {
  const { href, children, width, height } = props;
  return (
    <>
      {href ? (
        <NextLink
          href={href}
          style={{
            width,
            height,
          }}
        >
          {children}
        </NextLink>
      ) : (
        children
      )}
    </>
  );
}

interface Props {
  // children?: React.ReactNode;
  width?: number | string;
  height?: number | string;
  size?: number | string;
  src?: string;
  type?: "src" | "bg";
  bgSize?: React.CSSProperties["backgroundSize"];
  boxSx?: SxProps;
  href?: string;
}

export default function Image(props: Props) {
  const {
    width = 0,
    height = 0,
    size = 0,
    src = "",
    type = "src",
    bgSize = "cover",
    boxSx = {},
    href = "",
  } = props;
  const handleWidth = width || size;
  const handleHeight = height || size;

  const handleSx: any = {};
  if (type === "bg") {
    handleSx.backgroundImage = `url(${src})`;
    handleSx.backgroundSize = bgSize;
    handleSx.backgroundRepeat = "no-repeat";
  }
  return (
    <Link href={href} width={handleWidth} height={handleHeight}>
      <Stack
        width={handleWidth}
        height={handleHeight}
        sx={{
          ...handleSx,
          ...boxSx,
        }}
      >
        {type === "src" ? (
          <img
            src={src}
            alt=""
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        ) : null}
      </Stack>
    </Link>
  );
}
