import PageArchitecture from "@/components/public/page-architecture";
import HomePage from "@/components/pc/home";
import SwitchTerminal from "@/components/public/switch-terminal";

Home.getLayout = (page: React.ReactNode) => (
  <PageArchitecture hasH5={true} isFooter={true}>
    {page}
  </PageArchitecture>
);
export default function Home() {
  return (
    <SwitchTerminal pc={<HomePage />} h5={null} hasMultipleTerminal={false} />
  );
}
